// src/RandomComplaint.js

import React, { useState } from 'react';

const complaints = [
    "Emily drank too much Red Bull and now I can't keep up.",
    "Emily is sweeter than the pound of candy she eats every day.",
    "Emily has been way too nice to me today.",
    "Emily has only sent me one obnouxiously hot selfie today",
    "Emily eats enough sour gummy worms to feed a small village and still looks like a supermodel.",
    "Emily's outfits are illegally cute.",
    "I can't stop thinking about how much I want Emily.",
    "Emily is hotter than a box of Taco Bell fire sauce.",
    "Emily got cuter again today, against direct orders.",
    "I miss Emily's face.",
    "Emily is making everyone jealous again by being such a beautiful person.",
    "Emily hasn't poured me a Ferrari shot yet today.",
    "Emily drives electric cars to remote areas with no charging stations.",
  ];

const RandomComplaint = () => {
  const [complaint, setComplaint] = useState("");

  const generateComplaint = () => {
    const randomIndex = Math.floor(Math.random() * complaints.length);
    setComplaint(complaints[randomIndex]);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>What did Emily do now?</h2>
      <p style={styles.complaint}>{complaint || "Click the button to generate a complaint!"}</p>
      <button onClick={generateComplaint} style={styles.button}>
        Generate Complaint
      </button>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    padding: '50px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#e0f7fa', // light blue background
    borderRadius: '10px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '2rem',
    color: '#007acc', // darker blue for the title
    marginBottom: '20px',
  },
  complaint: {
    fontSize: '1.25rem',
    marginBottom: '20px',
    color: '#004080', // darker blue for the complaint text
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#007acc', // Emily's favorite blue for the button
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
  },
};

export default RandomComplaint;
